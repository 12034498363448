.profile-buttons{
    height: 28px;
    width: 141.01px;
    border-radius: 5px;
    background-color: #f5f8fa;
    color: black;
    border: solid 1px rgb(181, 181, 181)
}


.profile-buttons:hover{
    color: white;
    background-color: #ff9548;
    border: none;
}

.active-button {
    color: white;
    background-color: #ff9548;
    border: none;
}

.profile-buttons:active{
    background-color: black;
}

.admin-pro-pic{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    /* background-image: url("../images/testimonialImg.jpg"); */
    background-size: cover;
    background-position: center center;
}

.admin-profile-update-fields{
    height: 43px;
}

.form-container h6{
font-weight: 400;
}

.form-select{

    height: 43px;
    text-align: center

}

@media screen and (min-width: 375px) and (max-width: 991px){
    .profile-buttons-update{
        width: inherit;
    }}

    @media screen and (min-width: 991px) and (max-width: 2500px){
        .profile-buttons-update{
            width: 345px;
        }}

.profile-buttons-update{
    height: 43px;
    border-radius: 5px;
    background-color: #FF9548;
    color: white;
    border: none;

}

.bgwhite{
    background-color: white;
}

.program-cards
{
    height: 116px;
    width: 260px;
    border-radius: 5px;
}

.program-cards:hover{
    background-color: rgb(88, 88, 248);
    color: white;
    cursor: pointer;
}

.border-file-upload{
    border: 3px rgb(220, 220, 220) dashed;
    border-radius: 8px; 
    padding-left: 100px;
    padding: 50px;
    width: 100%;
}

.P-Update-Success-1{
    height: 100%;
      width: 100%;
      background-color: rgba(0,0,0,0.7);
      top: 0;
      left: 0;
      z-index: 10;
  }

  .P-Update-Success-2{
    background-color: white;
      width: 414px;
      z-index: 20;
  }
  
  .prev:hover{
    cursor: pointer;
  }

  .next:hover{
    cursor: pointer;
  }

  .rh5v-DefaultPlayer_component {
    background-color: #f5f8fa;
  }


  
.nav-searchicon{
    height: 18px;
    width: 18px;
    }
    
    .nav-searchbtn{
        border: none;
        background-color: transparent;
        position: absolute;
        left: 303px;
        top: 4px;
    }

    .searchbar-custom{
        width: 315px;
        border: none;
        background-color: #f5f8fb;
        padding-left: 28px;
    }

    .yourCourses h1{
        font-size: 18px;
    }

    .yourCourses h6{
        font-size: 15px;
    }


    
.scroll-section{
    overflow-y: auto;
}

.scroll-container {
    height: 87%;
}

.right-container {
    height: 100vh;
    overflow: hidden;
}

.fit-content-h {
    height: fit-content;
}

@media screen and (max-width:991px) {
    .right-container {
        height: fit-content;
    }

    .scroll-container {
        height: 100%;
    }
}