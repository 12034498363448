.verify-box{
    height: 40px;
    width: 36px;
    border-radius: 6px;
    border: 2px solid rgb(207, 207, 207);
    text-align: center;
}

.verify-otp-btn{
    height: 41px;
    width: 283px;
    border-radius: 8px;
    background-color: #FF9548;
    color: #F2F7FB;
    border: none;
    margin-bottom: 116px;
    margin-top: 72px;
}

.update-pass-btn{
    height: 41px;
    width: 283px;
    border-radius: 8px;
    background-color: #FF9548;
    color: #F2F7FB;
    border: none;
    margin-bottom: 86px;
    margin-top: 28px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    -webkit-appearance: none;
    margin: 0;
}