.signin-btn {
  height: 41px;
  width: 283px;
  border-radius: 8px;
  background-color: #FF9548;
  color: #F2F7FB;
  border: none;
  margin-top: 26px;
}


.user{
height: 41px;
width: 283px;
border-radius: 8px;
background-color: #F2F7FB;
border: none;
margin-top: 10px;
margin-bottom: 18px;
padding-left: 37px;
}

.errMsg{
  color: red;
  font-size: 13px;
}

.successMsg{
  margin-top: 10px;
  color: rgb(16, 155, 16);
  font-size: 13px;
}

.userSignin{
  height: 41px;
width: 283px;
border-radius: 8px;
background-color: #F2F7FB;
border: none;
margin-top: 10px;
padding-left: 37px;
}

.user::placeholder {
opacity: 0.19;
color: #111;
font-size: 14px;
line-height: 16px;
}


#role{
height: 41px;
width: 283px;
border-radius: 8px;
background-color: #F2F7FB;
border: none;
margin-top: 10px;
margin-bottom: 18px;
padding-left: 37px;
}


#role::placeholder{
height: 41px;
width: 283px;
border-radius: 8px;
background-color: #F2F7FB;
border: none;
margin-top: 10px;
margin-bottom: 18px;
padding-left: 37px;
}

.flex-container {
height: 100vh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.signin-container{
height: 700px;
width: 1024px;
background-color: #ffffff;
display: flex;
justify-content: center;
box-shadow: 0px 12px 25px rgba(1,1,1,0.2);
justify-content: space-between;
}

.signin-image{
height: 624px;
width: 442px;
background-image: url("../images/loginimg.jpg");
background-repeat: no-repeat;
background-size: cover;
  background-position-x: 62%;
  position: relative;

}

.color-fill {
background-color: rgba(1,225,147,0.35);
opacity: 0.9;
height: 700px;
width: 571px;
position: absolute;
top: 0;
left: 0;
}

.signin-heading{
color: #111;
font-size: 29px;
line-height: 35px;
margin-bottom: 50px;
}

.signin-content{
display: flex;
flex-direction: column;
width: 453px;
height: 100%;
align-items: center;
}

.e-heading{
color: #111;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
margin: 0px;
}


.p-heading{
color: #111;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
margin: 0px;
}

.logo{
height: 86px;
width: 86px;
background-color:#fbfcfe;
margin-bottom: 18px;
border-radius: 50px;
}

.logo img {
margin: 27px 26px 23px 22px;
}

.btn2{
color: #0058A3;
font-size: 10px;
margin-right: 197px;
line-height: 11px;
text-decoration: none;
background:none;
border:none;
margin:0;
padding:0;
cursor: pointer;
}

.checkbox{
margin-right: 8px;
color: #111;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
height: 18px;
width: 18px;
}

.additional-options{
width: 283px;
display: flex;
justify-content: space-between;
align-items: center;
}

.cookies-permission p{
color: #111;
font-size: 10px;
letter-spacing: NaNpx;
line-height: 11px;
}

.cookies-permission{
display: flex;
align-items: center;
}


.login-form{
background-color: #f2f7fb;
}

.login-back-container{
height: 100vh;
width: 100vw;
}

.login-details-container{
width: 372px;
margin-top: 42px;
}


@media screen and (min-width: 375px) and (max-width: 830px){
.signin-image{
    display:none;
}}

@media screen and (min-width: 375px) and (max-width: 830px){
  .login-details-container{
    height: 578px;
    width: 428px;
  }}

  @media screen and (min-width: 375px) and (max-width: 465px){
    .login-details-container{
      height: 578px;
      width: 355px;
    }}

    @media screen and (min-width: 375px) and (max-width: 465px){
      .signin-heading{
        font-size: 27px;
      }}
