body{
    background-color: #f5f8fa;
}


.bggreen{
    background-color: #789C71;
    border-radius: 7px;
}

.whitecolor{
    background-color: white;
}

.bgorrange{
    background-color: #FF9548;
    border-radius: 7px;
}

.bgyellow{
    background-color: #FFB000;
    border-radius: 7px;
}

/* Main Container */

/* .dashboard-container {
    width: 88vw;
    position: absolute;
    right: 0;
} */

@media screen and (min-width:991px) {
    .dashboard-container {
        width: 88vw;
    position: absolute;
    right: 0;
    }}

    @media screen and (min-width:375px) and (max-width: 934px){
        .bgorrange {
            height: 216px;
        }}

        @media screen and (min-width:375px) and (max-width: 934px){
            .bggreen {
                height: 216px;
            }}

            @media screen and (min-width: 870px){
                .bggreen{
                    width: 395px;
                }}

                @media screen and (min-width: 870px){
                    .bgorrange{
                        width: 395px;
                    }}

                    @media screen and (min-width: 870px){
                        .bgyellow{
                            width: 395px;
                        }}

h1{
  color: #000;
  font-size: 24px;
  font-weight: 500;
  line-height: 21px;
}

.scrollme th{
    padding: 20px;
    text-align: center;
    font-weight: 300;
}

.scrollme td{
    padding: 20px;
    text-align: center;
}

.dashboard-card-1-heading{
    color: #FFF;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
}

.dashboard-card-2-numbers{

    color: #FFF;
  font-size: 19px;
  font-weight: 500;
  line-height: 9px;

}

.db-totalstudents-number{
  
    color: #FFF;
  font-size: 23px;
  font-weight: 500;
  line-height: 9px;

}

.progress-bar{
    width: 32px;
    height: 7px;
    border-radius: 2px;
    background-color: #44bb80;
}

.progress-bar-0{
    background-color: #eef3f7;
    width: 32px;
    height: 7px;
    border-radius: 2px;
}

/* .backarrow{
    height: 22px;
    width: 31px;
} */

.backarrow{
    cursor: pointer;
}

.Progress-bar-batch-details{
    width: 128px;
    height: 4px;
    border-radius: 2px;
    --bs-progress-bar-bg: #ffb000;
}

.card-batch-list{
    background-color: #a6b4fb;
    width: 100px;
}

.bgwhite-batchdetails{
    background-color: white;
    width: 262px;
    border-radius: 3px;
}

.assign-prog-border{
    background-color: #f5f8fa;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 4px;
    width: 165px;
}

.assign-prog-border:hover{
    background-color: #FF9548;
    color: white;
    cursor: pointer;
}

.batch-details:hover{
    cursor: pointer;
    color: blue;
}

.add-prog-pop{
    background-color: #eeeeee;
    width: 332+px;
    display: flex;
    justify-content: center;
    position: relative;
}

.cancelimg{
    position: absolute;
    right: 10px;
    top: 25%;
}

.cancelimg:hover{
    cursor: pointer;
}

.atb-btn{
    width: 90%;
    height: 47px;
    border-radius: 5px;
    background-color: #f5f8fa;
    color: black;
    border: solid 1px rgb(237, 237, 237);
}

.atb-btn:hover{
    color: white;
    background-color: #ff9548;
    border: none;
}

.m-left-auto{
    margin-left: auto;
}

.scrollme{
    overflow-x: auto;
}

.programdetails-accordian{
    border: none;
}

.assignment-details-width{
    max-width: 298px;
}

.borderoutline{
    border: 1px solid rgb(127, 127, 127);
    border-radius: 5px;
    height: fit-content;
    width: 181px;
}

.remove-btn:hover{
cursor: pointer;
}

.more:hover{
cursor: pointer;
}

.more-assignment-hover:hover{
    cursor: pointer;
}

.cancelimg2{
    width: 8%;
    right: 14px;
    top: 19px;
}

.cancelimg2:hover{
    cursor: pointer;
}

.P-Update-Success-4{

    background-color: white;
    height: 234px;
      width: 334px;
      z-index: 20;

}


.yourCourses{
    height: fit-content;
    width: 557px;
}

    @media screen and (min-width:375px) and (max-width: 667px){
        .yourCourses-main-container {
            display: flex;
            z-index: 10;
         justify-content: center;
        }}
    

.yourCourses:hover{
    cursor: pointer;
}

.assignments-img{
    height: 16px;
    width: 16px;
    margin-top: 2px;
}

.change-pass{
    color: #1464aa;
    text-decoration: underline;
    font-weight: 400;
}

.change-pass:hover{
    cursor: pointer;
}

video img{
    width: 100%;
}

.dropdown-more{
    background-color: white;
    border: none;
    border-radius: 50%;
}

.dropdown-more:hover{
    background-color: white;
    border: none;
    border-radius: 50%;
}

.dropdown-more:focus{
    box-shadow: none;
    border-radius: 50%;
}

.dropdown-toggle::after {
    display: none !important; 
  }

  .nav1{
    display: block;
}

.nav2{
    display: none;
}

  @media screen and (max-width: 992px) {
    .nav1{
        display: none;
    }
    .nav2{
        display: block;
    }
}


.edit-dropdown{
    bottom: 1%;
    right: 1%;

}

.update-picture{
    border: none;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    background-color: #f5f8fa;
}

.update-picture:hover{
    border: none;
    background-color: #FF9548;
    color: white;
}

.dropdown-more {
    background-color: white;
    border: none;
    border-radius: 50%;
}


.nav-searchicon{
    height: 18px;
    width: 18px;
    }
    
    .nav-searchbtn{
        border: none;
        background-color: transparent;
        position: absolute;
        left: 303px;
        top: 4px;
    }

    .searchbar-custom{
        width: 315px;
        border: none;
        background-color: #f5f8fb;
        padding-left: 28px;
    }

    .yourCourses h1{
        font-size: 18px;
    }

    .yourCourses h6{
        font-size: 15px;
    }

    
.scroll-section{
    overflow-y: auto;
}

.scroll-container {
    height: 90%;
}

.right-container {
    height: 100vh;
    overflow: hidden;
}

.fit-content-h {
    height: fit-content;
}

@media screen and (max-width:991px) {
    .right-container {
        height: fit-content;
    }

    .scroll-container {
        height: 100%;
    }
}

.filter-icon{
    height: 16px;
    width: 28;
}

.offline-icon{
    height: 52px;
    width: 52px;
}

.wrapperOffline {
    height: 100vh;
}

.msgBoxOffline{
    background-color: #789C71;
    color: white;
    border-radius: 8px;
    border-bottom: 8px solid #FFB000;
}

.msgBoxOffline h3{
    margin-bottom: 0px;
    margin-top: 12px;
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: #dee2e6;
    border-bottom-width: 1px;
}