.sidebar-height{
    height: 100vh;
}


.bgwhite{
    background-color: white;
}

li:hover {
    cursor: pointer;
  }

.sidebar-options{
    font-size: 9px;
    text-align: center;
    font-weight: 600;
}


.side-icons{

    background-image: url("../images/sidepic1.png");
    height: 10px;
    width: 10px;
    background-repeat: no-repeat;
    display: block;
    margin-left: auto;
    margin-right: auto;

}


@media screen and (min-width:370px) and (max-width: 991px){
    .sidebar {
       display: none;
    }}