@font-face {
  font-family: "ProximaNova-Regular.otf";
  src: local("ProximaNova-Regular.otf"),
   url("../fonts/Proximanova/ProximaNova-Regular.otf") format("truetype");
  font-weight: bold;
  }



* {
  font-family: "ProximaNova-Regular.otf";
    padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  }
  
  body{
  background-color: #ffffff;
  }
  
  p {
    margin: 0px;
  }
/* 
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); ;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) ;
  }
  
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
  } */

  ::-webkit-scrollbar {
    width: 8px !important;
    height: 5px !important;
}
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .3);
    border-radius: 10px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}


/* fontsizes */
.font12{
  font-size: 12px;
}
.font14{
  font-size: 14px;
}
.font16{
  font-size: 14px;
}
.font18{
  font-size: 18px;
}
.font20{
  font-size: 20px;
}
.font24{
  font-size: 24px;
}
.font28{
  font-size: 28px;
}
.font30{
  font-size: 30px;
}
.font32{
  font-size: 32px;
}
.font36{
  font-size: 36px;
}
/* colors */
.blue{
  color: blue !important;
}
.black60{
  color: rgba(0, 0, 0, 0.6);
}
/* background colors */
.background-black {
  background-color: black;
}
.background-aliceblue{
  background-color: #f5f8fa;
}

/* border radius */
.radius5{
  border-radius: 5px;
}
.radius10{
  border-radius: 10px;
}
.radius15 {
  border-radius: 15px;
}
.radius20 {
  border-radius: 20px;
}
.radiushalf{
  border-radius: 50%;
}

/* others */
.pointer {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}

/* accordion styles */
.accordion-button{
  padding: 5px;
  background-color: #f5f8fa;
}
.accordion-button:not(.collapsed) {
  background: none !important;
  box-shadow: none !important;
  /* color: black !important; */
}
.accordion-body{
  padding: .5rem 1.5rem;
}
.accordion-button:focus{
  border: none !important;
  box-shadow: none !important;
}
/* video styles */
.videotag{
  width: 100%;
}